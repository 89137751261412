import logo from "../../img/500x500-full.png";
import {Link} from "react-router-dom";
import {useState} from "react";
import {Nav} from '../Nav';
import Config from "../Config";
import React from "react";

export const Login = () => {
    const apiUrl = Config();
    const [email, setEmail] = useState('');
    const [emailVal, setEmailVal] = useState(true | false);
    const [passwordVal, setPasswordVal] = useState(true | false);
    const [password, setPassword] = useState('');
    const handleEmailChange = (e) => {
        // add email to state
        setEmail(e);
        setPasswordVal(true);
    }
    const handlePasswordChange = (e) => {
        // add password to state
        setPassword(e);
        setEmailVal(true);
    }

    //127.0.0.1:8000
    const login = async () => {
        console.log("here");
        // const response = await fetch('https://api.jouwapplicatie.nl/api/login', {
        const response = await fetch(apiUrl + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        });
        const responseData = await response.json();
        console.log(responseData);
        // check 404
        if (response.status === 404)
            setEmailVal(false);
        if (response.status === 401)
            setPasswordVal(false);
        if (response.ok) {
            // cookies.set('token', responseData.token, { path: '/' });
            // cookies.set('name', responseData.user.name, { path: '/' });
            // cookies.set('email', responseData.user.email, { path: '/' });
            // cookies.set('role', responseData.user.role, { path: '/' });
            localStorage.setItem('token', responseData.token);
            localStorage.setItem('name', responseData.user.name);
            localStorage.setItem('email', responseData.user.email);
            localStorage.setItem('role', responseData.user.role);
            window.location.href = '/account';
        } else {
            console.log(responseData.message);
        }
    }
    if (localStorage.getItem('token')) {
        window.location.href = '/account';
    }else{
        return (
                <section className="sticky bg-gray-50 dark:bg-gray-900">
                    <Nav/>
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                        <Link to="/">
                            <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                <img className="w-8 h-8 mr-2" src={logo}
                                     alt="logo"/>
                                Jouw Applicatie
                            </a>
                        </Link>
                        <div
                            className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    Sign in to your account
                                </h1>
                                <form className="space-y-4 md:space-y-6" action="#">
                                    <div>
                                        <label htmlFor="email"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                                            email</label>
                                        <input type="email" onChange={(e) => handleEmailChange(e.target.value)} name="email" id="email"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               placeholder="name@company.com" required=""/>
                                        <div className={(emailVal ? "hidden" : "")}>
                                            <div className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                     fill="currentColor" viewBox="0 0 20 20"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <div>
                                                <span
                                                    className="font-medium">This mail address does not exist, please create a account.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="password"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                        <input type="password" onChange={(e) => handlePasswordChange(e.target.value)} name="password" id="password" placeholder="••••••••"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               required=""/>
                                        <div className={(passwordVal ? "hidden" : "")}>
                                            <div className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                     fill="currentColor" viewBox="0 0 20 20"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <div>
                                                <span
                                                    className="font-medium">This password is not correct, try again.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input id="remember" aria-describedby="remember" type="checkbox"
                                                       className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                       required=""/>
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember
                                                    me</label>
                                            </div>
                                        </div>
                                        <a
                                           className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot
                                            password?</a>
                                    </div>
                                    <button type={"button"} onClick={(e) => login()}
                                            className="w-full text-white bg-blue-600 hover:bg-blue-400 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 bg-primary-700 focus:ring-primary-800">Log in
                                    </button>
                                    <Link to="/register">
                                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                            Don’t have an account yet? <a
                                                                          className="font-medium text-blue-700 hover:text-blue-800 hover:underline dark:text-primary-500">Sign
                                            up</a>
                                        </p>
                                    </Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}