import React from "react";
import {Nav} from "../Nav";

export const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <Nav/>
            <div className="max-w-screen-xl mx-auto bg-white rounded-lg shadow-md p-6">
                <h1 className="text-2xl font-bold mb-4">Privacy and Cookie Policy</h1>

                <p className="mb-4">
                    <strong>Last updated:</strong> 24-01-2024 at 9:51
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">Collected Information</h2>
                <p>
                    We collect various types of information, including personal data such as name, email address, phone numbers, and card numbers. This information is collected when you register on our site, participate in a survey, or fill out a form.
                </p>
                <h2 className="text-2xl font-bold mt-4 mb-2">Use of Collected Information</h2>
                <p>
                    The collected information is used to provide you with personalized services, participate in marketing activities, and improve your experience on our site. We will not share your personal information with third parties without your consent, unless required by law.
                </p>

                <h2 className="text-lg font-bold mb-2">Cookies</h2>
                <p className="mb-4">
                    We use cookies to enhance your browsing experience and analyze website traffic. By using our website, you consent to our use of cookies in accordance with our Privacy Policy.
                </p>

                <h2 className="text-lg font-bold mb-2">Third-party Services</h2>
                <p className="mb-4">
                    We may use third-party services, such as Google Analytics, to track and analyze website usage. These third-party services may also use cookies to collect information about your interactions with our website.
                </p>

                <h2 className="text-lg font-bold mb-2">Your Rights</h2>
                <p className="mb-4">
                    You have the right to access, correct, or delete your personal information held by us. If you have any questions or concerns about your privacy or our Privacy Policy, please contact us at jonawoning@gmail.com.
                </p>

                <h2 className="text-lg font-bold mb-2">Changes to This Policy</h2>
                <p className="mb-4">
                    We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website.
                </p>

                <p className="text-sm mb-4">
                    By using our website, you agree to our Privacy and Cookie Policy. We encourage you to review this Privacy Policy periodically for any changes. Your continued use of our website after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                </p>

                <h2 className="text-lg font-bold mb-2">Storage and Deletion of Data</h2>
                <p className="mb-4">
                    Our approach with JavaScript LocalStorage ensures that data is immediately deleted when you log out of your account. We do not store data on external servers and do not have access to personal information beyond the mentioned data necessary for the functionality of the website.
                </p>

                <h2 className="text-lg font-bold mb-2">Data Security</h2>
                <p className="mb-4">
                    We take strict security measures to protect your data against unauthorized access, loss, misuse, or alteration. Only authorized personnel have access to this data, and we ensure continuous monitoring and updates of our security systems.
                </p>

                <h2 className="text-lg font-bold mb-2">External Links</h2>
                <p className="mb-4">
                    Our website may contain links to third-party external websites. This privacy policy applies only to our website. We are not responsible for the privacy policies of other websites accessible through links on our site.
                </p>
                <p className="text-sm mb-4">
                    By using our website, you agree to our privacy and cookie policy. We reserve the right to change this policy at any time. It is advisable to regularly check our policy for any updates.
                </p>

                <p className="text-sm">Thank you for trusting JouwApplicatie!</p>
            </div>
        </div>
    );
};