import React from "react";
import {Sidebar} from "../dashboard/Sidebar";
import {Hero} from "../dashboard/extra/Hero";

export const ProfileDashboard = () => {
    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    }else{
        return (
            <div className="">
                <Sidebar>
                    <Hero/>
                </Sidebar>
            </div>
        );
    }
}