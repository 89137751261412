import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Config from "../Config";
import axios from "axios";

export const Checkout = () => {
	const [loading, setLoading] = useState(false);
	const apiUrl = Config();

	const handleDownload = async (product, version) => {
		try {
			const response = await axios.get(apiUrl + "/download/" + product + "/" + version, {
				responseType: "blob",
				headers: {
					'Content-Type': 'application/json',
					"Authorization": "Bearer " + localStorage.getItem('token')
				},
			});

			// Maak een blob URL aan voor het gedownloade bestand
			const url = window.URL.createObjectURL(new Blob([response.data]));

			// Creëer een link element en download het bestand
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Kingdom-${version}.jar`);
			document.body.appendChild(link);
			link.click();

			// Verwijder het link element na het downloaden
			document.body.removeChild(link);
		} catch (error) {
			console.error('Error downloading file:', error);
		}
	}

	const handleClick = async () => {
		setLoading(true);

		// Vervang 'jouw_backend_url' door de URL waar je Laravel backend is gehost
		const response = await fetch(apiUrl + '/createcheckout', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				"Authorization": "Bearer " + localStorage.getItem('token')
			},
		});

		const session = await response.json();

		const stripe = await loadStripe('pk_test_51K57ZxKo3lRx22OeBcrCClyYPFllD7EbCcbRrDQFUqLVpE1WevHNFnFTuGzBRB5KCzuHUH8aFNhs80Yn3MtkKHYk00CdGQFfvB'); // Vervang dit door je eigen publishable key

		const result = await stripe.redirectToCheckout({
			sessionId: session.sessionId,
		});

		if (result.error) {
			console.error('Error:', result.error.message);
		}

		setLoading(false);
	};

	return (
		<div>
			<div className="m-10">
				<h1>Productnaam</h1>
				<p>Prijs: €19.99</p>
				<button onClick={handleClick} disabled={loading}>
					{loading ? 'Laden...' : 'Betalen'}
				</button>
			</div>
			<div>
				<button onClick={() => handleDownload("KingdomPlugin", "1.16")} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
					Download
				</button>
			</div>
		</div>
	);
}