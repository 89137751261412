import React from "react";
import {Routes, Route} from 'react-router-dom';

import {Home} from './components/pages/Home';
import {Login} from './components/user/Login';
import {Register} from './components/user/Register';
import {Logout} from './components/user/Logout';
import {ProfileDashboard} from './components/user/ProfileDashboard';
import {Services} from "./components/dashboard/Services";
import {Products} from "./components/pages/Products";
import {Checkout} from "./components/stripe/Checkout";
import {NotFound} from "./components/extra/NotFound";
import {PrivacyPolicy} from "./components/extra/PrivacyPolicy";
import {Users} from "./components/dashboard/admin/users/Users";
import {Orders} from "./components/dashboard/admin/orders/Orders";

function App() {
    return (
        <div className="bg-slate-500 h-max m-0 p-0">
            <div>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    <Route path="/products" element={<Products/>}/>

                    <Route path="/account" element={<ProfileDashboard/>}/>
                    <Route path="/account/services" element={<Services/>}/>

                    <Route path="/checkout" element={<Checkout/>}/>

                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>

                    <Route path="/admin/users" element={<Users/>}/>

                    <Route path="/admin/orders" element={<Orders/>}/>

                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </div>
        </div>


    );
}

export default App;
