import React from "react";
import logo from "../img/500x500-full.png";
import {
    Navbar,
    Collapse,
    Typography,
    Button,
    IconButton,
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    PencilIcon,
    EnvelopeOpenIcon,
    DocumentDuplicateIcon,
    AcademicCapIcon,
} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";

export const Nav = () => {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    const navList = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link to="/products">
                    <a href="#" className="flex items-center font-semibold hover:text-blue-500 hover:scale-95">
                        <AcademicCapIcon className="h-[15px] w-[15px] mr-1"/>
                        Products
                    </a>
                </Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <a href="#" className="flex items-center font-semibold hover:text-blue-500 hover:scale-95">
                    <PencilIcon className="h-[15px] w-[15px] mr-1"/>
                    Portfolio
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <a href="#" className="flex items-center font-semibold hover:text-blue-500 hover:scale-95">
                    <EnvelopeOpenIcon className="h-[15px] w-[15px] mr-1"/>
                    Contact
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <a href="#" className="flex items-center font-semibold hover:text-blue-500 hover:scale-95">
                    <DocumentDuplicateIcon className="h-[15px] w-[15px] mr-1"/>
                    Docs
                </a>
            </Typography>
        </ul>
    );

    return (
        <>
            <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-2.5">
                <div className="flex items-center justify-between text-blue-gray-900">
                    {/*<Typography*/}
                    {/*    as="a"*/}
                    {/*    href="/"*/}
                    {/*    variant="h4"*/}
                    {/*    className="mr-4 cursor-pointer py-1.5 font-bold hover:text-blue-500"*/}
                    {/*>*/}
                    {/*    Jouw Applicatie*/}
                    {/*</Typography>*/}
                    <Link to="/">
                        <img src={logo} className="object-cover cursor-pointer w-16 hover:scale-90" alt={logo}/>
                    </Link>
                    <div className="flex items-center gap-4">
                        <div className="mr-4 hidden lg:block">{navList}</div>
                        <Link to={(localStorage.getItem('token') != null ? "/account" : "/login")}>
                            <Button
                                variant="gradient"
                                size="sm"
                                className="hidden lg:inline-block hover:scale-105"
                            >
                                <span className="flex items-center gap-2 py-2 pr-4">
                                    <UserCircleIcon className="h-[18px] w-[18px]"/>
                                    {(localStorage.getItem('token') != null ? "Account" : "Log in")}
                                </span>
                            </Button>
                        </Link>
                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                </div>
                <Collapse open={openNav}>
                    {navList}
                    <Link to={(localStorage.getItem('token') != null ? "/account" : "/login")}>
                        <Button variant="gradient" size="sm" fullWidth className="mb-2">
                            <span>{(localStorage.getItem('token') != null ? "Account" : "Log in")}</span>
                        </Button>
                    </Link>
                </Collapse>
            </Navbar>
        </>
    )
}
//                            <span>
//                                 <UserCircleIcon className="h-[13px] w-[13px]"/>
//                                 My Account
//                             </span>