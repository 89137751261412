import {Card, Typography} from "@material-tailwind/react";
import DocumentMeta from 'react-document-meta';
import React from "react";
import {Nav} from "../Nav";
import widgetBanner from "../../img/widget_banner.png";
import {Link} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {Footer} from "../Footer";

export const Home = () => {

    const meta = {
        title: 'Home - Jouw Applicatie',
        description: 'Jouw Applicatie, de plek waar jij aanvragen kan doen voor bepaalde services of jouw producten kan beheren. jouwapplicatie.nl',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'jouwapplicatie, jouw, applicatie'
            }
        }
    }

    return (
        <DocumentMeta {...meta}>
            <Nav/>
            <CookieConsent
                location="bottom"
                buttonText="I want cookies!"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#377ef0" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
                <span style={{ fontSize: "12px" }}>Go here to our&nbsp;
                    <Link to="/privacy-policy" className="cursor-pointer text-blue-200 hover:text-blue-400">Privacy Policy</Link>.
                </span>
            </CookieConsent>
            <section className="bg-white dark:bg-gray-900">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="lg:col-span-7">
                        <div className="lg:mx-auto lg:max-w-2xl">
                            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                                Jouw Applicatie
                            </h1>
                            <p className="mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                                De plek waar jij terecht kan voor een applicatie of plugin die jij nodig hebt!
                            </p>
                            <Link to="/account/services">
                                <a
                                    href="#"
                                    className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                                >
                                    Account
                                    <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="lg:col-span-5 lg:pl-6 lg:py-6 lg:pr-12 lg:relative lg:w-full">
                        <img src={widgetBanner} className="w-full h-auto animate-pulse lg:inset-y-0 lg:right-0"
                             alt="mockup"/>
                    </div>
                </div>
                <video className="rounded-lg" controls>
                    <source src="https://docs.material-tailwind.com/demo.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </section>
            <Footer/>
        </DocumentMeta>
    )
}