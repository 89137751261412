import logo from "../../img/500x500-full.png";
import {Link} from "react-router-dom";
import {useState} from "react";
import React from "react";
import {Nav} from "../Nav";
import Config from "../Config";

export const Register = () => {
    const apiUrl = Config();
    const [email, setEmail] = useState('');
    const [emailVal, setEmailVal] = useState(false | true);
    const [emailVal2, setEmailVal2] = useState(false | true);
    const [password, setPassword] = useState('');
    const [passVal, setPassVal] = useState(false | true);
    const [name, setName] = useState('');

    const handleEmailChange = (e) => {
        // add email to state
        setEmail(e);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailVal(false);
        } else {
            setEmailVal(true);
        }
    }

    const handlePasswordChange = (e) => {
        setPassword(e);
        let caps, small, num, specialSymbol;
        caps = (e.match(/[A-Z]/g) || []).length;
        small = (e.match(/[a-z]/g) || []).length;
        num = (e.match(/[0-9]/g) || []).length;
        specialSymbol = (e.match(/\W/g) || []).length;
        if (specialSymbol > 0 && num > 0 && small > 0 && caps > 0 && e.length > 4) {
            setPassVal(true);
        }else{
            setPassVal(false);
        }
    }

    // register function
    const register = async () => {
        setEmailVal2(true);
        if (passVal && emailVal){
            const response = await fetch(apiUrl + '/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    name: name
                })
            });
            const responseData = await response.json();
            console.log(responseData);
            // check 404
            if (response.status === 404) {
                setEmailVal2(false);
            }
            if (response.ok) {
                localStorage.setItem('token', responseData.token);
                localStorage.setItem('name', responseData.user.name);
                localStorage.setItem('email', responseData.user.email);
                localStorage.setItem('role', responseData.user.role);
                window.location.href = '/';
            }
        }
    }

    if (localStorage.getItem('token')) {
        window.location.href = '/account';
    } else {
        return (
            <section className="bg-gray-50 dark:bg-gray-900">
                <Nav/>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <Link to="/">
                        <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img className="w-8 h-8 mr-2" src={logo}
                                 alt="logo"/>
                            Jouw Applicatie
                        </a>
                    </Link>
                    <div
                        className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Register your account
                            </h1>
                            <form className="space-y-4 md:space-y-6" action="#">
                                <div>
                                    <label htmlFor="name"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                                        name</label>
                                    <input type="name" onChange={(e) => setName(e.target.value)} name="name" id="name"
                                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           placeholder="John" required=""/>
                                </div>
                                <div>
                                    <label htmlFor="email"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                                        email</label>
                                    <input type="email" onChange={(e) => handleEmailChange(e.target.value)} name="email"
                                           id="email"
                                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           placeholder="name@company.com" required=""/>
                                    <div className={(emailVal ? "hidden" : "")}>
                                        <div
                                            className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                            role="alert">
                                            <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                 fill="currentColor" viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd"
                                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                      clipRule="evenodd"></path>
                                            </svg>
                                            <div>
                                                <span
                                                    className="font-medium">This is not a correct mail address.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(emailVal2 ? "hidden" : "")}>
                                        <div
                                            className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                            role="alert">
                                            <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                 fill="currentColor" viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd"
                                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                      clipRule="evenodd"></path>
                                            </svg>
                                            <div>
                                                <span
                                                    className="font-medium">This mail address is already in use, please use something different.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="password"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input type="password" onChange={(e) => handlePasswordChange(e.target.value)} name="password"
                                           id="password" placeholder="••••••••"
                                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           required=""/>
                                    <div className={(passVal ? "hidden" : "flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400")}
                                        role="alert">
                                        <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span className="sr-only">Danger</span>
                                        <div>
                                            <span className="font-medium">Ensure that these requirements are met:</span>
                                            <ul className="mt-1.5 ml-4 list-disc list-inside">
                                                <li>At least 8 characters (and up to 100 characters)</li>
                                                <li>At least one lowercase character</li>
                                                <li>Inclusion of at least one special character, e.g., ! @ # ?</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="terms" aria-describedby="terms" type="checkbox"
                                               className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                               required=""/>
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I
                                            accept the <a
                                                className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                            >Terms and Conditions</a></label>
                                    </div>
                                </div>
                                <button type={"button"} onClick={(e) => register()}
                                        className="w-full text-white bg-blue-600 hover:bg-blue-400 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 bg-primary-700 focus:ring-primary-800">Create
                                    an account
                                </button>
                                <Link to="/login">
                                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                        Already have an account? <a
                                        className="font-medium text-blue-700 hover:text-blue-800 hover:underline dark:text-primary-500">Login
                                        here</a>
                                    </p>
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}