import React from "react";
import {Link} from "react-router-dom";
import logo from "../img/500x500-full.png";

export const Footer = () => {
    return (
        <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
            <div className="m-10"></div>
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="/"
                       className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-8" alt="JouwApplicatie"/>
                        <span
                            className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">JouwApplicatie</span>
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                        {/*<li>*/}
                        {/*    <a href="#" className="hover:underline me-4 md:me-6">About</a>*/}
                        {/*</li>*/}
                        <li>
                            <Link to="/privacy-policy">
                                <a className="hover:underline me-4 md:me-6">Privacy Policy</a>
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a href="#" className="hover:underline">Contact</a>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a
                href="/" className="hover:underline">JouwApplicatie™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}