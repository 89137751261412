import React from 'react';
import image from "../../../img/Business_SVG.svg";
import {Link} from "react-router-dom";

export const Hero = () => {
    return (
        <div className="p-4 sm:ml-64 mt-10">
            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-center">
                    <div className="flex items-center justify-center p-4 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
                        <img src={image} alt="" className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />
                    </div>
                    <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                        <h1 className="text-5xl font-bold leadi sm:text-6xl">Dashboard <br/>
                        </h1>
                        <p className="mt-6 mb-8 text-lg sm:mb-12">Everything you need, right here!
                            <br/> <p className="hidden md:inline lg:hidden">Check out the products you have or services you have bought.</p>
                        </p>
                        <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
                            <Link to="/account/services">
                                <button
                                    className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
                                    My Services
                                </button>
                            </Link>
                            <a rel="noopener noreferrer" href="/logout" className="px-8 py-3 text-lg font-semibold rounded dark:bg-violet-400 dark:text-gray-900 dark:hover:bg-violet-500 focus:outline-none focus:ring-2 focus:ring-violet-400 focus:ring-opacity-50 hover:bg-gray-700">Log out</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};