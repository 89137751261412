import DocumentMeta from 'react-document-meta';
import React from "react";
import {Nav} from "../Nav";
import {Footer} from "../Footer";
import {ShoppingCart} from "phosphor-react";

export const Products = () => {

    const meta = {
        title: 'Products - Jouw Applicatie',
        description: 'Jouw Applicatie, de plek waar jij OorlogSimulatie, Kingdom of andere plugins kan kopen! jouwapplicatie.nl',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'jouwapplicatie, jouw, applicatie'
            }
        }
    }

    return (
        <DocumentMeta {...meta}>
            <Nav/>
            <div>
                <div className="justify-items-center justify-center text-center mt-12">
                    <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                        All available products/minecraft plugins
                    </h1>
                    <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
                        At jouwapplicatie.nl you can buy minecraft plugins, websites and a lot of other stuff. But on this page you can buy prebuilt minecraft plugins.
                    </p>
                </div>
                <section id="Projects"
                         className="mt-20 w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mb-20">
                    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                        <a href={(localStorage.getItem('token') != null ? "https://buy.stripe.com/8wM17w43Z5Qh9nW289" : "/login")}>
                            <img
                                src="https://assetsio.reedpopcdn.com/minecraft-medieval-castle.jpg?width=1200&height=600&fit=crop&enable=upscale&auto=webp"
                                alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>
                            <div className="px-4 py-3 w-72">
                                <span className="text-gray-400 mr-3 uppercase text-xs">Minecraft 1.16.4 / 1.20.2</span>
                                <p className="text-lg font-bold text-black truncate block capitalize">Kingdom Plugin</p>
                                <div className="flex items-center">
                                    <p className="text-lg font-semibold text-black cursor-auto my-3">$14.95</p>
                                    <del>
                                        <p className="text-sm text-gray-600 cursor-auto ml-2">€25</p>
                                    </del>
                                    <div className="ml-auto">
                                        <div
                                            className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                            <ShoppingCart size={25}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                        <a href={(localStorage.getItem('token') != null ? "https://buy.stripe.com/4gw2bA43Z1A143C4gp" : "/login")}>
                            <img
                                src="https://proxy.builtbybit.com/ae1a2a161f29678ad9bc54ef95957198959642ff?url=https%3A%2F%2Fi.gyazo.com%2F845136f096be00c485824cbf4ad10c00.jpg"
                                alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>
                            <div className="px-4 py-3 w-72">
                                <span className="text-gray-400 mr-3 uppercase text-xs">Minecraft 1.8.9 / 1.20.2</span>
                                <p className="text-lg font-bold text-black truncate block capitalize">OorlogSimulatie
                                    Plugin</p>
                                <div className="flex items-center">
                                    <p className="text-lg font-semibold text-black cursor-auto my-3">$19.95</p>
                                    <del>
                                        <p className="text-sm text-gray-600 cursor-auto ml-2">$30</p>
                                    </del>
                                    <div className="ml-auto">
                                        <div
                                            className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                            <ShoppingCart size={25}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                        <a href={(localStorage.getItem('token') != null ? "https://buy.stripe.com/fZeg2q9oj4MdgQo9AK" : "/login")}>
                            <img
                                src="https://i.imgur.com/nORTHsC.png"
                                alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>
                            <div className="px-4 py-3 w-72">
                                <span className="text-gray-400 mr-3 uppercase text-xs">Minecraft 1.8.9 / 1.20.2</span>
                                <p className="text-lg font-bold text-black truncate block capitalize">OorlogSimulatie
                                    Private</p>
                                <div className="flex items-center">
                                    <p className="text-lg font-semibold text-black cursor-auto my-3">$34.95</p>
                                    <div className="ml-auto">
                                        <div
                                            className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                            <ShoppingCart size={25} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </section>

            </div>
            <Footer/>
        </DocumentMeta>
    )
}